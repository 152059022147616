import { navigate } from 'gatsby';
import React from 'react';

import Button from '@material-ui/core/Button';
import { Theme, withStyles } from '@material-ui/core/styles';

export const WhiteButton = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.black.main,
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
}))(Button);

type CallButtonProps = {
  title?: string;
  onBookCall?: () => void;
};

export const CallButton: React.FC<CallButtonProps> = (props) => {
  const { title = 'Book a call with our team', onBookCall } = props;

  const onClick = () => {
    onBookCall?.();
    navigate('/schedule-call');
  };

  return (
    <WhiteButton variant="contained" onClick={onClick}>
      {title}
    </WhiteButton>
  );
};
