import React from 'react';

const VisaSVG: React.FC = () => {
  return (
    <svg
      width="34"
      height="24"
      viewBox="0 0 34 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0H34V24H0V0Z" fill="white" />
      <path
        d="M13.873 8.153L10.468 16.274H8.248L6.571 9.792C6.47 9.392 6.381 9.247 6.071 9.079C5.567 8.805 4.733 8.548 4 8.389L4.05 8.149H7.624C7.85765 8.14828 8.08385 8.23114 8.26174 8.38263C8.43964 8.53411 8.55749 8.74422 8.594 8.975L9.478 13.675L11.664 8.148L13.872 8.153H13.873ZM22.574 13.623C22.584 11.479 19.611 11.361 19.631 10.403C19.637 10.111 19.915 9.801 20.521 9.723C21.2315 9.65396 21.9474 9.77835 22.593 10.083L22.962 8.361C22.3336 8.12504 21.6682 8.0028 20.997 8C18.921 8 17.458 9.104 17.447 10.685C17.433 11.855 18.49 12.507 19.286 12.895C20.104 13.294 20.38 13.548 20.376 13.905C20.37 14.449 19.723 14.69 19.118 14.699C18.063 14.716 17.448 14.415 16.96 14.186L16.58 15.966C17.07 16.191 17.977 16.387 18.916 16.397C21.124 16.397 22.566 15.307 22.574 13.619V13.623ZM28.057 16.267H30.001L28.305 8.146H26.512C26.3203 8.14377 26.1324 8.19934 25.9727 8.30548C25.8131 8.41161 25.6891 8.56338 25.617 8.741L22.465 16.267H24.671L25.109 15.054H27.804L28.057 16.267ZM25.713 13.389L26.82 10.353L27.452 13.396L25.713 13.389ZM16.875 8.146L15.137 16.267H13.036L14.774 8.146H16.874H16.875Z"
        fill="#1A1F71"
      />
    </svg>
  );
};

export default VisaSVG;
