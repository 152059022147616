import React, { useCallback, useMemo } from 'react';

import { ErrorMsg, parseErrors, parseTouched } from '../../utils';
import FormErrorContext from './FormError.context';

interface WizardProviderProps {
  value: {
    errors: Record<string, unknown>; // FormikErrors
    touched: Record<string, unknown>; // FormikTouched
  };
  children: React.ReactNode;
}

const FormErrorProvider = ({
  value,
  children,
}: WizardProviderProps): JSX.Element => {
  const { errors, touched } = value;

  const touchedFields = useMemo(() => parseTouched(touched), [touched]);

  const [errorFields, hasTouchedError] = useMemo(() => {
    const errorFields = parseErrors(errors).map((err: ErrorMsg) => err.field);
    const hasTouchedError = errorFields.some((fieldName) =>
      touchedFields?.includes(fieldName),
    );

    return [errorFields, hasTouchedError];
  }, [errors, touchedFields]);

  const hasErrorFiled = useCallback(
    (fieldName: string) => errorFields.includes(fieldName),
    [errorFields],
  );

  return (
    <FormErrorContext.Provider
      value={{ errorFields, touchedFields, hasTouchedError, hasErrorFiled }}
    >
      {children}
    </FormErrorContext.Provider>
  );
};

export default FormErrorProvider;
