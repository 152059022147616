import { gql } from '@apollo/client';

export const GET_AVAILABILITY = gql`
  query Data($retreatId: ID!) {
    availabilities {
      getAvailabilityByRetreat(retreatId: $retreatId) {
        id
        retreatId
        extras {
          id
          cost
          type
        }
        rooms {
          quantity
          type
          numberOfBeds
          petType
          prices {
            easterPrice
            defaultPrice
            christmasPrice
          }
        }
      }
    }
  }
`;
