import { navigate } from 'gatsby';
import React, { useEffect, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useLocation } from '@reach/router';

import { GET_BOOKING } from '../../graphql';
import { useStore } from '../../hooks';
import { CmsQuoteForm } from '../../views/quote/types';
import { QuoteOffline } from '../QuoteOffline';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 152,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 600,
    },
  },
  button: {
    marginLeft: '36px',
  },
  header: {
    marginBottom: 67,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 20,
    },
  },
}));

const paymentSucces = ['captured', 'confirmed'];

const QuoteProcessing: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const { setBookingQuote } = useStore((state) => state);
  const getBookingId = new URLSearchParams(location.search).get('bookingId');

  const { data, loading } = useQuery<CmsQuoteForm>(GET_BOOKING, {
    variables: { getBookingId: getBookingId },
  });

  const quote = useMemo(() => data && data.bookings.getBooking, [data]);

  const redirecting: JSX.Element = useMemo(
    () => (
      <Box className={classes.header}>
        <Typography variant="h3" gutterBottom>
          We are redirecting you. Please wait.
        </Typography>
      </Box>
    ),
    [classes.header],
  );

  useEffect(() => {
    if (quote && quote.isPaidOnline && !paymentSucces.includes(quote.status)) {
      setBookingQuote(quote);
      navigate('/quote-confirmation');
    }
  }, [quote, setBookingQuote]);

  return (
    <Container maxWidth="md" className={classes.root}>
      <Grid container justifyContent="center" spacing={4}>
        <Grid item xs={12}>
          {quote ? (
            paymentSucces.includes(quote.status) ? (
              <Box className={classes.header}>
                <Typography variant="h3" gutterBottom>
                  This quote is already paid.
                </Typography>
                <Grid container>
                  <Typography variant="h5">To create new quote:</Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate('/quote')}
                    className={classes.button}
                  >
                    New Quote
                  </Button>
                </Grid>
              </Box>
            ) : quote.isPaidOnline ? (
              redirecting
            ) : (
              <QuoteOffline />
            )
          ) : loading ? (
            redirecting
          ) : (
            <Box className={classes.header}>
              <Typography variant="h3" gutterBottom>
                This quote cannot be found
              </Typography>
              <Grid container>
                <Typography variant="h5">To create new quote :</Typography>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate('/quote')}
                  className={classes.button}
                >
                  New Quote
                </Button>
              </Grid>
            </Box>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default QuoteProcessing;
