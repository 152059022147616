import React, { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Autocomplete, Input } from '@dogtainers/dgt-blocks/src/components/form';
import { useIsMobile } from '@dogtainers/dgt-blocks/src/hooks/useBreakpoints';
import {
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { useStore } from '../../../hooks';
import {
  POSITIVE_NUMBERS,
  handleAutoFocus,
  handleFocusField,
  processListForAutocomplete,
  processRetreatRooms,
} from '../../../utils';
import { PageFormProps } from '../index';

const petTypes = [
  { label: 'Cat', value: 'cat' },
  { label: 'Dog', value: 'dog' },
];

export const QuotePage2: React.FC<PageFormProps> = ({
  values,
  touched,
  handleChange,
  handleBlur,
  errors,
  updateForm,
  children,
  isErrorsVisible,
  isFieldInFocus,
}) => {
  type RoomPartial = Exclude<typeof values.rooms, undefined>[number];

  const { booking } = useStore((state) => state);
  const { availability } = useStore((state) => state);
  const isMobile = useIsMobile();

  const roomsList = useMemo(
    () =>
      processRetreatRooms(availability?.rooms) ||
      (booking?.rooms ? [booking?.rooms[0]?.roomType] : []),
    [availability, booking],
  );

  const addRoom = (): RoomPartial => {
    const oldRooms: RoomPartial[] = values.rooms ?? [];
    const newRoom: RoomPartial = {
      id: uuidv4(),
      petType: '',
      roomType: '',
      quantity: undefined,
    };
    updateForm({
      ...values,
      rooms: [...oldRooms, newRoom],
    });
    return newRoom;
  };

  const rooms: RoomPartial[] = values.rooms?.length
    ? [...values.rooms]
    : [addRoom()];

  function deleteRoom(room: RoomPartial): void {
    const updatedRooms = rooms.filter((p) => p.id !== room.id);
    updateForm({
      ...values,
      rooms: updatedRooms,
    });
  }

  return (
    <Grid container justifyContent="center" spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h4">Room details</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          Time to tell us about our guest or guests{' '}
        </Typography>
      </Grid>
      {children}
      {rooms.map((room, idx) => (
        <React.Fragment key={room.id}>
          {rooms.length > 1 && (
            <Grid item xs={12} style={{ marginTop: '1rem' }}>
              <List dense>
                <ListItem style={{ padding: 0 }}>
                  <ListItemText>
                    <Typography variant="h5">Room {idx + 1}</Typography>
                  </ListItemText>
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => deleteRoom(room)}
                      style={{ paddingRight: 0 }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </Grid>
          )}
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  label="Room type"
                  name={`rooms[${idx}].roomType`}
                  menuItems={processListForAutocomplete(roomsList)}
                  error={errors?.rooms?.[idx]?.roomType}
                  touched={touched?.rooms?.[idx]?.roomType}
                  value={rooms?.[idx]?.roomType}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isErrorVisible={isErrorsVisible}
                  innerRef={isFieldInFocus?.(`rooms.${idx}.roomType`)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  label="Pet type"
                  name={`rooms[${idx}].petType`}
                  menuItems={petTypes}
                  error={errors?.rooms?.[idx]?.petType}
                  touched={touched?.rooms?.[idx]?.petType}
                  value={rooms?.[idx]?.petType}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isErrorVisible={isErrorsVisible}
                  innerRef={isFieldInFocus?.(`rooms.${idx}.petType`)}
                  onFocus={(e) => {
                    handleFocusField(isMobile, e.target);
                  }}
                  key={`${!errors?.rooms?.[idx]?.roomType}_.petType`}
                  autoFocus={handleAutoFocus(
                    isMobile,
                    !rooms?.[idx]?.roomType || errors?.rooms?.[idx]?.roomType,
                    rooms?.[idx]?.petType,
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Input
                  label="Quantity"
                  regex={POSITIVE_NUMBERS}
                  name={`rooms[${idx}].quantity`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={rooms?.[idx]?.quantity}
                  error={String(errors?.rooms?.[idx]?.quantity ?? '')}
                  touched={touched?.rooms?.[idx]?.quantity}
                  isErrorVisible={isErrorsVisible}
                  innerRef={isFieldInFocus?.(`rooms.${idx}.quantity`)}
                  onFocus={(e) => {
                    handleFocusField(isMobile, e.target);
                  }}
                  key={`${!errors?.rooms?.[idx]?.petType}_.quantity`}
                  autoFocus={handleAutoFocus(
                    isMobile,
                    !rooms?.[idx]?.petType || errors?.rooms?.[idx]?.petType,
                    rooms?.[idx]?.quantity,
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      ))}
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Button variant="outlined" size="large" onClick={() => addRoom()}>
          Add another room
        </Button>
      </Grid>
    </Grid>
  );
};
