import React from 'react';

const CardSVG: React.FC = () => {
  return (
    <svg
      width="34"
      height="24"
      viewBox="0 0 34 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0H34V24H0V0Z" fill="white" />
      <path d="M20.65 6H13V18.495H20.65V6Z" fill="#FF5F00" />
      <path
        d="M13.9622 11.945C13.9614 10.7419 14.2342 9.55438 14.76 8.47227C15.2858 7.39016 16.0508 6.44182 16.9972 5.699C15.825 4.77758 14.4172 4.20455 12.9348 4.0454C11.4523 3.88625 9.95498 4.14741 8.61392 4.79903C7.27286 5.45065 6.1422 6.46643 5.35116 7.73027C4.56012 8.99411 4.14062 10.455 4.14062 11.946C4.14062 13.437 4.56012 14.8979 5.35116 16.1617C6.1422 17.4256 7.27286 18.4414 8.61392 19.093C9.95498 19.7446 11.4523 20.0058 12.9348 19.8466C14.4172 19.6875 15.825 19.1144 16.9972 18.193C16.0506 17.45 15.2853 16.5013 14.7595 15.4188C14.2337 14.3364 13.9611 13.1484 13.9622 11.945V11.945Z"
        fill="#EB001B"
      />
      <path
        d="M29.097 16.8695V16.6125H29.207V16.5595H28.944V16.6125H29.048V16.8695H29.097V16.8695ZM29.607 16.8695V16.5595H29.527L29.435 16.7805L29.342 16.5605H29.262V16.8695H29.32V16.6345L29.406 16.8355H29.466L29.552 16.6355V16.8695H29.607V16.8695ZM29.855 11.9455C29.8553 13.4365 29.4361 14.8975 28.6453 16.1614C27.8545 17.4254 26.724 18.4413 25.3831 19.093C24.0421 19.7448 22.5448 20.0061 21.0624 19.847C19.5799 19.6879 18.1722 19.1149 17 18.1935C17.9462 17.4501 18.7112 16.5015 19.2373 15.4192C19.7633 14.337 20.0367 13.1494 20.0367 11.946C20.0367 10.7427 19.7633 9.5551 19.2373 8.47284C18.7112 7.39058 17.9462 6.44195 17 5.69854C18.1722 4.77729 19.5799 4.20441 21.0623 4.04535C22.5447 3.8863 24.0419 4.1475 25.3828 4.79909C26.7238 5.45069 27.8544 6.4664 28.6454 7.73014C29.4364 8.99387 29.8559 10.4547 29.856 11.9455H29.855Z"
        fill="#F79E1B"
      />
    </svg>
  );
};

export default CardSVG;
