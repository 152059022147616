import { gql } from '@apollo/client';

export const ADD_EXTRAS = gql`
  mutation AddExtras($bookingId: ID!, $extras: [AddExtrasInput!]) {
    bookings {
      addExtras(bookingId: $bookingId, extras: $extras) {
        id
        retreatId
        startDate
        endDate
        rooms {
          roomType
          petType
          quantity
        }
        totalCost {
          currency
          amount
        }
        status
        contact {
          nameFirst
          nameLast
          email
          phone
          postcode
          isDefenceMember
          isPensioner
          isReturningCustomer
        }
        paymentIntentId
        isPaidOnline
        extrasCost {
          currency
          amount
        }
        extras {
          type
          cost
          quantity
        }
        roomCost {
          currency
          amount
        }
        discount
        onlineBookingRestrictionReason
        deliveryAddres {
          address
          postcode
          gps
          placeId
        }
        pickupAddres {
          address
          postcode
          gps
          placeId
        }
      }
    }
  }
`;
