import { Fragment, useContext, useEffect, useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import { Image } from '@dogtainers/dgt-blocks/src/components';
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';

import { GET_BOOKING_RETREAT } from '../../graphql';
import { useEnsureQuoteForm, useImages, useStore } from '../../hooks';
import { LoadingContext } from '../../layouts/site.layout';
import { CmsQuoteInfo } from '../../types';
import {
  calculateDayDateDiff,
  calculateDiscount,
  roundToTwoDigitsAfterDecimal,
} from '../../utils';
import { Extras } from '../../views';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 100,
  },
  header: {
    marginBottom: 39,
  },
  image: {
    width: 198,
    height: 136,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 327,
      width: '100%',
      height: 225,
    },
  },
  summariesContainer: {
    marginBottom: 4,
  },
  summaries: {
    padding: '28px 0',
  },
  total: {
    padding: 28,
    borderRadius: 12,
    background: '#fff',
    marginBottom: 54,
  },
  fees: {
    margin: '12px 0',
  },
  cost: {
    fontSize: 14,
    lineHeight: '150%',
  },
  extras: {
    paddingBottom: 32,
  },
  icon: {
    color: '#6EAF56',
    width: 15,
    height: 15,
    marginRight: 6,
  },
}));

export const Confirmation: React.FC = () => {
  const classes = useStyles();
  const { getImageData } = useImages();
  const { setOverlay } = useContext(LoadingContext);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const bookingQuote = useStore((state) => state.bookingQuote);
  const [extrasCost, setExtrasCost] = useState(0);

  useEnsureQuoteForm();

  const { data, loading } = useQuery<CmsQuoteInfo>(GET_BOOKING_RETREAT, {
    variables: { getRetreatId: bookingQuote?.retreatId },
  });

  const [getRetreat, extras] = useMemo(
    () =>
      data
        ? [
            data.retreats.getRetreat,
            data.availabilities.getAvailabilityByRetreat.extras,
          ]
        : [],
    [data],
  );

  useEffect(() => {
    loading ? setOverlay('Loading...') : setOverlay(undefined);
  }, [loading, setOverlay]);

  const choosePicture = (roomType: string, petType: string) => {
    const imgSrc = `confirmation-quote/${
      petType === 'cat' ? 'Cat' : petType === 'dog' ? 'BigDog' : 'Other'
    }.png`;

    return (
      <Image
        getImageData={getImageData}
        src={imgSrc}
        alt={`${roomType} for ${petType}`}
        options={{
          maxWidth: '100%',
          maxHeight: '100%',
        }}
      />
    );
  };

  return (
    <Container maxWidth="md" className={classes.root}>
      <Grid container justifyContent="center" spacing={4}>
        <Grid item xs={12}>
          <Box className={classes.header}>
            <Typography variant="h3" gutterBottom>
              Confirmation
            </Typography>
            <Divider />
          </Box>

          {!loading && (
            <>
              <Box className={classes.summariesContainer}>
                <Typography variant={matches ? 'h6' : 'h5'}>Summary</Typography>
                <Box className={classes.summaries}>
                  {bookingQuote &&
                    bookingQuote.rooms?.map(
                      ({ roomType, petType, quantity }) => (
                        <Fragment key={roomType}>
                          <Box
                            display="flex"
                            flexDirection={matches ? 'column' : 'row'}
                            padding={matches ? 0 : '24px 0'}
                          >
                            <Box className={classes.image}>
                              {choosePicture(roomType || '', petType || 'cat')}
                            </Box>
                            <Box
                              flex="1"
                              display="flex"
                              flexDirection="column"
                              justifyContent="space-between"
                            >
                              <Box
                                display="flex"
                                alignItems={matches ? 'flex-start' : 'flex-end'}
                                justifyContent="space-between"
                                flexDirection="column"
                                mb={matches ? 2.5 : 0}
                              >
                                <Box
                                  display="flex"
                                  width={'100%'}
                                  justifyContent="space-between"
                                  flexDirection={
                                    matches ? 'column-reverse' : 'row'
                                  }
                                >
                                  <Typography>
                                    <span
                                      style={{ textTransform: 'capitalize' }}
                                    >
                                      {roomType} at {getRetreat?.title}
                                    </span>
                                  </Typography>
                                  <Typography
                                    style={{
                                      paddingBottom: matches ? 16 : 0,
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <StarIcon className={classes.icon} />
                                    <b>{getRetreat?.rating || 5}</b>
                                  </Typography>
                                </Box>
                                <Box
                                  display="flex"
                                  width={'100%'}
                                  justifyContent="space-between"
                                  flexDirection={matches ? 'column' : 'row'}
                                >
                                  <Typography style={{ paddingBottom: 16 }}>
                                    {quantity} {petType}
                                    {quantity > 1 && 's'}
                                    {` · ${
                                      calculateDayDateDiff(
                                        bookingQuote.startDate,
                                        bookingQuote.endDate,
                                      ) + 1
                                    } days`}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          <Divider />
                        </Fragment>
                      ),
                    )}
                </Box>
              </Box>

              {extras && (
                <Box className={classes.summaries}>
                  <Typography
                    variant={matches ? 'h6' : 'h5'}
                    gutterBottom
                    className={classes.extras}
                  >
                    Extras
                  </Typography>

                  <Extras extrasData={extras} setExtrasCost={setExtrasCost} />
                </Box>
              )}

              <Box className={classes.total}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  className={classes.fees}
                  mb={4}
                >
                  <Typography variant="h6" className={classes.cost}>
                    Booking price
                  </Typography>
                  <Typography variant="h6" className={classes.cost}>
                    $
                    {roundToTwoDigitsAfterDecimal(
                      bookingQuote?.roomCost?.amount,
                    )}
                  </Typography>
                </Box>

                <Box
                  display="flex"
                  justifyContent="space-between"
                  className={classes.fees}
                  mb={4}
                >
                  <Typography variant="h6" className={classes.cost}>
                    Extras
                  </Typography>
                  <Typography variant="h6" className={classes.cost}>
                    ${roundToTwoDigitsAfterDecimal(extrasCost)}
                  </Typography>
                </Box>

                <Box
                  display="flex"
                  justifyContent="space-between"
                  mb={4}
                  className={classes.fees}
                >
                  <Typography variant="h6" className={classes.cost}>
                    Discount
                  </Typography>
                  <Typography variant="h6" className={classes.cost}>
                    {bookingQuote?.discount || 0}%
                  </Typography>
                </Box>

                <Divider />

                <Box
                  display="flex"
                  justifyContent="space-between"
                  mb={4}
                  margin="26px 0 26px 0"
                >
                  <Typography variant="h6">
                    <b>Total</b>
                  </Typography>
                  <Typography variant="h6">
                    <b>
                      $
                      {roundToTwoDigitsAfterDecimal(
                        ((bookingQuote?.roomCost?.amount || 0) + extrasCost) *
                          calculateDiscount(bookingQuote?.discount),
                      )}
                    </b>
                  </Typography>
                </Box>
                <Box textAlign="center">
                  <Typography variant="body2" gutterBottom>
                    Price shown is the total price, <br /> including additional
                    fees and taxes
                  </Typography>
                </Box>
              </Box>

              <Box
                display="flex"
                justifyContent="flex-end"
                flexDirection={!matches ? 'row' : 'column-reverse'}
                mb={4}
              >
                <Button
                  variant="contained"
                  color="primary"
                  form="extras-form"
                  type="submit"
                >
                  Next
                </Button>
              </Box>
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
