import 'yup-phone';

import * as yup from 'yup';

import {
  EMAIL,
  NAME,
  PHONE,
  SPACE_BETWEEN,
  getSpaceErrorMessage,
} from '../../utils/regExp';

export const initValidationSchema = (): ReturnType<typeof yup.object> =>
  yup.object({
    retreatId: yup.string().required('Retreat is required'),
    startDate: yup.string().required(),
    endDate: yup
      .string()
      .test(
        'test dates',
        'Date must be greater than start date',
        function (endDate) {
          return (
            new Date(endDate as string).getTime() >
            new Date(this.parent.startDate as string).getTime()
          );
        },
      )
      .required(),
    rooms: yup
      .array()
      .of(
        yup.object({
          roomType: yup.string().required('Room type is required'),
          petType: yup.string().required('Pet type is required'),
          quantity: yup
            .number()
            .required('Quantity is required')
            .min(1, 'Quantity cant be less than 1'),
        }),
      )
      .required(),
    isDelivery: yup.boolean(),
    deliveryAddress: yup.object().when('isDelivery', {
      is: true,
      then: yup.object({
        address: yup.string().required('Delivery address is required '),
      }),
    }),
    isPickup: yup.boolean(),
    pickupAddress: yup.object().when('isPickup', {
      is: true,
      then: yup.object({
        address: yup.string().required('Pickup address is required '),
      }),
    }),
    contact: yup
      .object({
        nameFirst: yup
          .string()
          .matches(NAME, 'Please enter valid name')
          .min(2, 'Too Short!')
          .max(40)
          .required('First name is required'),
        nameLast: yup
          .string()
          .matches(NAME, 'Please enter valid last name')
          .min(2, 'Too Short!')
          .max(40)
          .required('Last name is required'),
        email: yup
          .string()
          .max(50)
          .matches(EMAIL, 'Please enter valid email')
          .matches(SPACE_BETWEEN, (value) => getSpaceErrorMessage(value.value))
          .required('Email is required'),
        phone: yup
          .string()
          .matches(PHONE, 'Invalid phone')
          .required('Phone is required'),
        postcode: yup
          .string()
          .test('contact.postcode', 'Invalid postcode', (val = '', context) => {
            const { from } = context as QuoteFormContext;
            const parent = from[1];
            const arrivalCountryId = parent?.value?.countryId;
            return getPostcodeRegex(arrivalCountryId).test(val);
          })
          .required('Delivery postcode is required'),
      })
      .required(),
  });

type QuoteFormContext = yup.TestContext & {
  from: yup.TestOptions[];
};

function getPostcodeRegex(countryId = 'AU'): RegExp {
  switch (countryId) {
    case 'AU':
      return /^(?:(?:[2-8]\d|9[0-7]|0?[28]|0?9(?=09))(?:\d{2}))$/;
    case 'NZ':
      return /^\d{4}$/;
    case 'GB':
      return /^[A-Z]{1,2}[0-9]{1,2}[A-Z]{0,1} ?[0-9][A-Z]{2}$/i;
    default:
      // just check non-blank, non-whitespace
      return /^(?!\s*$).+/;
  }
}

export type ErrorMsg = { field: string; message: string };
export function parseErrors(
  obj: Record<string, unknown>,
  parents: string[] = [],
): ErrorMsg[] {
  const errorMsgs: ErrorMsg[] = [];
  for (const [key, value] of Object.entries(obj)) {
    if (!value) {
      continue;
    }
    if (typeof value === 'string') {
      errorMsgs.push({ field: [...parents, key].join('.'), message: value });
    }
    if (typeof value === 'object') {
      errorMsgs.push(
        ...parseErrors(value as Record<string, unknown>, [...parents, key]),
      );
    }
  }
  return errorMsgs;
}
