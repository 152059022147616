import { gql } from '@apollo/client';

export const PAYMENT_BOOKING = gql`
  mutation InitializeBookingPayment($bookingId: ID!, $paymentMethodId: String) {
    bookings {
      initializeBookingPayment(
        bookingId: $bookingId
        paymentMethodId: $paymentMethodId
      ) {
        clientSecret
      }
    }
  }
`;
