import { FC, useMemo } from 'react';

import { BlockForm } from '@dogtainers/dgt-blocks/src/types/_blocks.types';

import { Contact, Quote } from '../../views';

type Props = { block: BlockForm };

export const FormBlock: FC<Props> = ({ block }) => {
  const { formId } = block;

  const Form = useMemo(() => {
    switch (formId) {
      case 'contact': {
        return Contact;
      }
      case 'quote':
        return Quote;
      default:
        throw Error(`Unknown formId "${formId}"`);
    }
  }, [formId]);

  return <Form />;
};
