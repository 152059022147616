import { gql } from '@apollo/client';

export const GET_BOOKING_RETREAT = gql`
  query Data($getRetreatId: ID!) {
    retreats {
      getRetreat(id: $getRetreatId) {
        title
        rating
        extras {
          label
        }
      }
    }
    availabilities {
      getAvailabilityByRetreat(retreatId: $getRetreatId) {
        id
        retreatId
        extras {
          id
          cost
          type
        }
        rooms {
          quantity
          type
          numberOfBeds
          petType
          prices {
            easterPrice
            defaultPrice
            christmasPrice
          }
        }
      }
    }
  }
`;
