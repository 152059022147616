import React from 'react';

import { Box, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 52,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 600,
    },
  },
  header: {
    marginBottom: 67,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 20,
    },
  },
}));

const QuoteOffline: React.FC = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="md" className={classes.root}>
      <Grid container justifyContent="center" spacing={4}>
        <Grid item xs={12}>
          <Box className={classes.header}>
            <Typography variant="h3" gutterBottom>
              Please contact us to finish quote
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">Contact:</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">
            Mon-Fri 8:30am-5:30pm, Sat 8am-12pm.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">AU 1300 788 965</Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default QuoteOffline;
