import { useFormik } from 'formik';
import { navigate } from 'gatsby';
import React, { Fragment, useEffect } from 'react';
import { CmsAddExtras } from 'types';

import { useMutation } from '@apollo/client';
import { theme } from '@dogtainers/cms-blocks';
import {
  Box,
  Checkbox,
  Divider,
  TextField,
  Typography,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';

import { ADD_EXTRAS } from '../../graphql';
import { useStore } from '../../hooks';
import { POSITIVE_WHOLE_NUMBERS, capitalizeWords } from '../../utils';

type ExtrasProps = {
  extrasData: { type: string; cost: number; id: string }[];
  setExtrasCost: React.Dispatch<React.SetStateAction<number>>;
};

type ExtrasSubmit = { id: string; quantity: number }[];

type ExtrasForm = {
  extras: {
    id: string;
    checked: boolean;
    type: string;
    quantity?: number | null;
    cost: number;
  }[];
};

const useStyles = makeStyles((theme) => ({
  extraFont: {
    fontSize: '16px',
    lineHeight: '165%',
  },
  costFont: {
    fontSize: 14,
    lineHeight: '150%',
    alignSelf: 'center',
  },
  inputField: {
    '& > div': {
      alignSelf: 'flex-end',
      border: '1px solid #E0E0E0',
      background: '#FFFFFF',
      borderRadius: '100px',
      padding: '8px 15px',
      width: 81,
      height: 30,
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
    },
  },
  divider: {
    margin: '24px 0',
  },
}));

const ExtrasForm: React.FC<ExtrasProps> = ({ extrasData, setExtrasCost }) => {
  const classes = useStyles();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const { setBookingQuote, bookingQuote } = useStore((state) => state);
  const [submitForm, { error }] = useMutation<CmsAddExtras>(ADD_EXTRAS);

  const formik = useFormik<ExtrasForm>({
    onSubmit: async (values: ExtrasForm) => {
      try {
        if (error) {
          console.info(`Submission error! ${error.message}`);
          throw Error(
            'Unable to add extras - please try again, or give us a call',
          );
        }
        if (!bookingQuote) {
          throw Error("We could'nt find any quote.");
        }
        const extras = values.extras?.reduce<ExtrasSubmit>(
          (acc, { checked, quantity, id }) =>
            checked && quantity ? [...acc, { id, quantity }] : acc,
          [],
        );

        if (extras) {
          const result = await submitForm({
            variables: {
              bookingId: bookingQuote.id,
              extras,
            },
          });
          result.data && setBookingQuote(result.data.bookings.addExtras);
        }
        navigate('/payment');
      } catch (e) {
        setBookingQuote(null);
        console.log(e);
      }
    },
    initialValues: {
      extras: extrasData.map(({ type, cost, id }) => {
        const bookedExtra = bookingQuote?.extras?.find(
          (el) => el.type === type,
        );
        return bookedExtra
          ? {
              quantity: bookedExtra.quantity,
              checked: true,
              type,
              cost,
              id,
            }
          : {
              checked: false,
              type,
              cost,
              id,
            };
      }),
    },
  });

  const { handleChange, values, handleSubmit } = formik;

  useEffect(
    () =>
      setExtrasCost(
        values.extras.reduce(
          (acc, extra) =>
            extra.checked && extra.quantity
              ? acc + extra.quantity * extra.cost
              : acc,
          0,
        ),
      ),
    [setExtrasCost, values],
  );

  return (
    <form id="extras-form" onSubmit={handleSubmit}>
      {extrasData?.map(({ cost, type }, idx) => (
        <Fragment key={type}>
          <Box key={type} display="flex">
            <Box display="flex" alignItems={matches ? 'flex-start' : 'center'}>
              <Checkbox
                name={`extras[${idx}].checked`}
                value={values.extras?.[idx]?.checked ?? false}
                checked={values.extras?.[idx]?.checked ?? false}
                onChange={handleChange}
                style={{ padding: 0 }}
              />
            </Box>
            <Box
              ml={matches ? '18px' : 2.5}
              flex="1"
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Box
                display="flex"
                alignItems={matches ? 'flex-start' : 'flex-end'}
                justifyContent="space-between"
                flexDirection={matches ? 'column' : 'row'}
                mb={matches ? 2.5 : 0}
              >
                <Typography
                  className={classes.extraFont}
                  style={{ paddingBottom: matches ? 16 : 0 }}
                >
                  {capitalizeWords(type)}
                </Typography>
                <Box
                  display="flex"
                  flexDirection="row"
                  gridGap={18}
                  width={170}
                  justifyContent="space-between"
                >
                  <TextField
                    type="number"
                    placeholder="0"
                    name={`extras[${idx}].quantity`}
                    className={classes.inputField}
                    onChange={handleChange}
                    value={Number(values.extras?.[idx]?.quantity) || ''}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    InputProps={{
                      inputProps: {
                        min: 0,
                        pattern: POSITIVE_WHOLE_NUMBERS,
                      },
                    }}
                  />
                  <Typography className={classes.costFont}>${cost}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Divider className={classes.divider} />
        </Fragment>
      ))}
    </form>
  );
};

export default ExtrasForm;
