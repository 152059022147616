import React from 'react';
import {
    Box,
    LinearProgress,
    Typography,
} from '@material-ui/core';

type ProgressProps = {
    pctComplete: number;
};

const Progress = ({pctComplete}: ProgressProps) => {
    return (
        <Box width="184px">
            <Typography variant="body2" color="textSecondary">
              {pctComplete}% complete
            </Typography>
            <LinearProgress variant="determinate" value={pctComplete} />
        </Box>
    )
}

export default Progress;