import React from 'react';

export interface IFormErrorContext {
  errorFields: string[];
  touchedFields: string[];
  hasTouchedError: boolean;
  hasErrorFiled: (fieldName: string) => boolean;
}

const defaultContextValues: IFormErrorContext = {
  errorFields: [],
  touchedFields: [],
  hasTouchedError: false,
  hasErrorFiled: () => false,
};

const FormErrorContext =
  React.createContext<IFormErrorContext>(defaultContextValues);

export default FormErrorContext;
