import { FC } from 'react';

import { Container, Grid, Typography, makeStyles } from '@material-ui/core';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { STRIPE_PUB_KEY } from '../../environment';
import { useEnsureQuoteForm } from '../../hooks';
import { Checkout } from '../../views';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 100,
  },
}));

const Payment: FC = () => {
  const classes = useStyles();

  useEnsureQuoteForm();

  return (
    <Container maxWidth="md" className={classes.root}>
      <Grid container justifyContent="center" spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h3" gutterBottom>
            Payment
          </Typography>

          <Typography variant="h5">Contact Details</Typography>

          <Elements stripe={STRIPE_PUB_KEY ? loadStripe(STRIPE_PUB_KEY) : null}>
            <Checkout />
          </Elements>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Payment;
