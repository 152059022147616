import classNames from 'classnames';
import { useFormik } from 'formik';
import { Link, navigate } from 'gatsby';
import React, {
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useMutation, useQuery } from '@apollo/client';
import { theme } from '@dogtainers/cms-blocks/src/theme';
import { PageControl } from '@dogtainers/dgt-blocks/src/components/form';
import { useErrorStyles } from '@dogtainers/dgt-blocks/src/components/form/form.styles';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Snackbar,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { WindowLocation, useLocation } from '@reach/router';

import { Image } from '../../components/image';
import FormErrorProvider from '../../contexts/FormError/FormError.provider';
import { GET_AVAILABILITY, MUTATION_SUBMIT_QUOTE_FORM } from '../../graphql';
import { useFindRetreatIdByTitle, useStore } from '../../hooks';
import { useImages } from '../../hooks/useImages';
import { useScrollToErrorField } from '../../hooks/useScrollToErrorField';
import { LoadingContext } from '../../layouts/site.layout';
import { CmsAvailability } from '../../types';
import {
  ErrorMsg,
  generateDate,
  mapFieldToPage,
  parseErrors,
} from '../../utils';
import { Booking } from '../booking/types';
import { ContactForm } from '../contact/contact';
import { QuotePage1, QuotePage2, QuotePage3 } from './components';
import {
  CmsSubmitBooking,
  QuoteFormPartial,
  QuoteFormPartialTouched,
} from './types';
import { initValidationSchema } from './validation';

export const useStyles = makeStyles(() => ({
  buttonWhite: {
    justifyContent: 'flex-start',
    backgroundColor: '#ffffff',
  },
  quoteHeader: {
    height: 130,
  },
  pageControl: {
    margin: '60px 0',
  },
  backHomeBtn: {
    marginRight: 29,
  },
  needHelpBtn: {
    textTransform: 'uppercase',
    marginLeft: 40,
  },
  logo: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  quoteForm: {
    display: 'flex',
    flexDirection: 'column',
  },
  errorList: {
    padding: 0,
    margin: '16px 0',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  error: {
    margin: 0,
    padding: 0,
    verticalAlign: 'unset',
    textDecoration: 'underline',
    textAlign: 'start',
  },
}));

export type PageFormProps = {
  values: QuoteFormPartial;
  touched: QuoteFormPartialTouched;
  errors: QuoteFormPartial;
  handleChange: ReturnType<typeof useFormik>['handleChange'];
  handleBlur: ReturnType<typeof useFormik>['handleBlur'];
  setFieldValue: (fieldNmae: string, value: unknown) => void;
  updateForm: (values: QuoteFormPartial) => void;
  nextPage: () => void;
  prevPage: () => void;
  children?: ReactNode;
  isErrorsVisible: boolean;
  isFieldInFocus?: (
    name: string,
  ) => React.MutableRefObject<null | HTMLInputElement> | undefined;
};

function getContactFormParams(
  location: WindowLocation<unknown>,
): Partial<ContactForm> {
  const params = new URLSearchParams(location.search);
  // Collect relevant values
  const formValues: Partial<ContactForm> = {
    nameFirst: params.get('nameFirst') ?? undefined,
    nameLast: params.get('nameLast') ?? undefined,
    email: params.get('email') ?? undefined,
    phone: params.get('phone') ?? undefined,
  };
  return formValues;
}

function getAnnouncementParams(
  location: WindowLocation<unknown>,
): Partial<Booking> {
  const params = new URLSearchParams(location.search);
  return {
    retreatTitle: params.get('retreatTitle') ?? undefined,
    startDate: params.get('startDate') ?? undefined,
    endDate: params.get('endDate') ?? undefined,
  };
}

function clearUrlParams(): void {
  if (typeof window === 'undefined') {
    return;
  }
  window.history.replaceState(
    {},
    document.title,
    // eslint-disable-next-line no-restricted-globals
    location.origin + location.pathname,
  );
}

const camelCaseToSentence = (text: string | undefined) =>
  text?.replace(/([a-z])([A-Z])/g, '$1 $2') ?? '';

const formElementId = 'form-submission-errors';

const FormQuote = () => {
  const location = useLocation();
  const { getImageData } = useImages();
  const classes = useStyles();
  const { errorFont, errorBlock } = useErrorStyles();
  const { setOverlay } = useContext(LoadingContext);
  const { setBookingQuote, setAvailability, booking, setBooking } = useStore(
    (state) => state,
  );

  const [isMounted, setMounted] = useState<boolean>(false);
  const [toastMsg, setToastMsg] = useState<string | null>(null);
  const [activeStep, setActiveStep] = useState(1);
  const [errorMsgs, setErrorMsgs] = useState<ErrorMsg[] | undefined>();
  const [availableSlots, setAvailableSlots] = useState(true);

  const {
    isFieldInFocus,
    fieldInFocus,
    setFieldInFocus,
    isErrorsVisible,
    setErrorsVisible,
  } = useScrollToErrorField(formElementId);

  const [submitForm, { loading, error }] = useMutation<CmsSubmitBooking>(
    MUTATION_SUBMIT_QUOTE_FORM,
  );

  const { retreatTitle, startDate, endDate } = getAnnouncementParams(location);

  const retreatIdFromURL = useFindRetreatIdByTitle(
    camelCaseToSentence(retreatTitle),
  );

  const initialValues = useMemo(
    () => {
      return {
        id: uuidv4(),
        startDate: booking?.startDate || new Date().toISOString().slice(0, 10),
        endDate:
          booking?.endDate ||
          generateDate(new Date(), 1).toISOString().slice(0, 10),
        retreatId: booking?.retreatId || '',
        rooms: booking
          ? booking.rooms?.map(({ petType, roomType }) => ({
              id: uuidv4(),
              petType,
              roomType,
              quantity: roomType ? 1 : undefined,
            }))
          : [
              {
                id: uuidv4(),
                petType: '',
                roomType: '',
                quantity: undefined,
              },
            ],
        contact: {
          isReturningCustomer: false,
          isDefenceMember: false,
          isPensioner: false,
          nameFirst: '',
          nameLast: '',
          email: '',
          phone: '',
          postcode: '',
        },
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [booking],
  );

  const createBookingData = ({
    startDate,
    endDate,
    rooms,
    id,
    isDelivery,
    deliveryAddress,
    pickupAddress,
    isPickup,
    ...rest
  }: QuoteFormPartial) => ({
    startDate: new Date(values.startDate || '').toISOString(),
    endDate: new Date(values.endDate || '').toISOString(),
    rooms: rooms?.map(({ roomType, petType, quantity }) => ({
      roomType,
      petType,
      quantity,
    })),
    deliveryAddres: deliveryAddress,
    pickupAddres: pickupAddress,
    ...rest,
  });

  const formik = useFormik<QuoteFormPartial>({
    initialValues,
    validationSchema: initValidationSchema(),
    onSubmit: async (values: QuoteFormPartial) => {
      setOverlay('Submitting quote..');
      try {
        if (loading) {
          console.info('Submitting...');
        }
        if (error) {
          console.info(`Submission error! ${error.message}`);
          throw Error(
            'Unable to submit form - please try again, or give us a call',
          );
        }

        const { data } = await submitForm({
          variables: {
            data: createBookingData(values),
          },
        });
        setToastMsg('Quote submitted!');
        (window?.dataLayer as { push: (o: { event: string }) => void })?.push({
          event: 'quoteRequestConfirmed',
        });

        if (data?.bookings.createBooking.isPaidOnline) {
          setBookingQuote(data.bookings.createBooking);
          setBooking(null);
          navigate('/quote-confirmation');
        } else if (
          data?.bookings.createBooking.onlineBookingRestrictionReason ===
          'Not enough timeslots.'
        ) {
          setToastMsg('Retreat unavailable for selected period.');
          setAvailableSlots(false);
        } else {
          setBooking(null);
          navigate('/quote-offline');
        }
      } catch (e) {
        setBookingQuote(null);
        const message = (e instanceof Error && e.message) || 'Unknown Error';
        setToastMsg(message);
        (window?.dataLayer as { push: (o: { event: string }) => void })?.push({
          event: 'quoteRequestFormSubmissionError',
        });
      } finally {
        setOverlay(undefined);
      }
    },
  });

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    isValid,
    values,
    touched,
    setValues,
    setFieldValue,
    isSubmitting,
  } = formik;

  const { retreatId } = values;

  const { data, refetch } = useQuery<CmsAvailability>(GET_AVAILABILITY, {
    variables: { retreatId: retreatId },
  });

  useEffect(() => {
    refetch({ retreatId });
  }, [refetch, retreatId]);

  useEffect(() => {
    if (data?.availabilities?.getAvailabilityByRetreat) {
      setAvailability(data.availabilities.getAvailabilityByRetreat);
    } else {
      setAvailability(null);
    }
  }, [data, setAvailability]);

  // Detect document ready state
  useEffect(() => {
    setMounted(true);

    // Contact Form
    for (const [key, value] of Object.entries(getContactFormParams(location))) {
      if (value) setFieldValue(`contact.${key}`, value);
    }

    // Quote step 1, values from URL params
    if (retreatIdFromURL) setFieldValue('retreatId', retreatIdFromURL);
    for (const [key, value] of Object.entries({ startDate, endDate })) {
      if (value) setFieldValue(key, value);
    }

    clearUrlParams();
  }, [endDate, location, retreatIdFromURL, setFieldValue, startDate]);

  function getActivePage(step: number): React.FC<PageFormProps> {
    switch (step) {
      case 1:
        return QuotePage1;
      case 2:
        return QuotePage2;
      case 3:
        return QuotePage3;
      default:
        throw Error(`Unknown form page #${step}`);
    }
  }

  const ActiveForm = getActivePage(activeStep);

  function updateForm(newData: QuoteFormPartial) {
    setValues({
      ...newData,
    });
  }

  function nextStep() {
    setActiveStep((c) => c + 1);
  }

  function prevStep() {
    setActiveStep((c) => c - 1);
  }

  function goToStep(step: number) {
    setActiveStep(step);
  }

  useEffect(() => {
    setErrorMsgs(parseErrors(errors));
  }, [errors]);

  function onClickError(field: string) {
    goToStep(mapFieldToPage(field));
    setFieldInFocus(field);
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      !fieldInFocus && window.scrollTo(0, 0);
    }
  }, [activeStep, fieldInFocus]);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!toastMsg}
        autoHideDuration={3000}
        onClose={() => setToastMsg(null)}
        message={toastMsg}
      />
      {isMounted ? (
        <Container>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className={classes.quoteHeader}
          >
            <Box display="flex" alignItems="center">
              <Link to="/" style={{ width: 100 }} className={classes.logo}>
                <Image
                  getImageData={getImageData}
                  src="logo_en-AU.png"
                  alt="Dogtainers Logo"
                  options={{}}
                />
              </Link>
            </Box>
            <Box>
              <Button
                variant="outlined"
                onClick={() => {
                  window?.zE?.('webWidget', 'open');
                }}
                className={classes.needHelpBtn}
              >
                Need help?
              </Button>
            </Box>
          </Box>
          <Container maxWidth="md">
            {!availableSlots && (
              <>
                <Typography
                  variant="h6"
                  className={classNames(errorBlock, errorFont)}
                >
                  Retreat Unavailable for Selected Period
                  <br />
                  Unfortunately, the retreat you've chosen is already reserved
                  for this time frame. Please consider selecting an alternative
                  date or retreat.
                </Typography>
                <br />
              </>
            )}
            <form
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
              className={classes.quoteForm}
            >
              <FormErrorProvider value={{ errors, touched }}>
                <ActiveForm
                  values={values}
                  errors={errors as QuoteFormPartial}
                  touched={touched as QuoteFormPartialTouched}
                  updateForm={updateForm}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  nextPage={nextStep}
                  prevPage={prevStep}
                  isFieldInFocus={isFieldInFocus}
                  isErrorsVisible={isErrorsVisible}
                >
                  {isErrorsVisible && !!errorMsgs?.length && (
                    <Grid item xs={12} id="form-submission-errors">
                      <Typography
                        variant="h6"
                        className={classNames(errorBlock, errorFont)}
                      >
                        Please Verify Details
                        <br />
                        In order to submit your request we need you to remedy
                        the errors below, select the error to jump to section:{' '}
                      </Typography>
                      <ul className={classes.errorList}>
                        {errorMsgs.map(({ field, message }) => (
                          <li
                            key={`${field}_${message}`}
                            className={errorBlock}
                          >
                            <Button
                              variant="text"
                              size="small"
                              onClick={() => onClickError(field)}
                              classes={{
                                label: errorFont,
                              }}
                              className={classes.error}
                            >
                              {message}
                            </Button>
                          </li>
                        ))}
                      </ul>
                    </Grid>
                  )}
                </ActiveForm>
              </FormErrorProvider>
              <Box className={classes.pageControl}>
                <PageControl
                  currentPage={activeStep}
                  totalPages={3}
                  nextPage={nextStep}
                  prevPage={prevStep}
                  isSubmitting={isSubmitting}
                  canSubmit={isValid}
                  setToastMsg={setToastMsg}
                  setErrorsVisible={setErrorsVisible}
                />
              </Box>
            </form>
          </Container>
        </Container>
      ) : (
        <Box
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            paddingTop: '25%',
            textAlign: 'center',
          }}
        >
          <CircularProgress size="4rem" />
        </Box>
      )}
    </>
  );
};

export default FormQuote;
