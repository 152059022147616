/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';

import {
  Autocomplete,
  Checkbox,
  Input,
  Radio,
} from '@dogtainers/dgt-blocks/src/components/form';
import { AutocompleteItem } from '@dogtainers/dgt-blocks/src/components/form/autocomplete';
import {
  FormControl,
  Grid,
  Typography,
  debounce,
  makeStyles,
} from '@material-ui/core';
import IconLock from '@material-ui/icons/LockOutlined';

import {
  AddressProps,
  getGooglePlaceDetails,
  getGooglePlacePredicitions,
} from '../../../utils';
import { PageFormProps } from '../index';
import { QuoteFormPartial } from '../types';

export const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.primary.main,
  },
}));

export const QuotePage3: React.FC<PageFormProps> = ({
  values,
  touched,
  handleChange,
  handleBlur,
  errors,
  setFieldValue,
  children,
  isErrorsVisible,
  isFieldInFocus,
}) => {
  const contact: QuoteFormPartial['contact'] = {
    ...values.contact,
  };
  const deliveryAddress: QuoteFormPartial['deliveryAddress'] = {
    ...values.deliveryAddress,
  };
  const pickupAddress: QuoteFormPartial['pickupAddress'] = {
    ...values.pickupAddress,
  };
  const isPickup: QuoteFormPartial['isPickup'] = values.isPickup;
  const isDelivery: QuoteFormPartial['isDelivery'] = values.isDelivery;

  const classes = useStyles();

  const [pickupAddresses, setPickupAddresses] = useState<AddressProps[]>([]);
  const [deliveryAddresses, setDeliveryAddresses] = useState<AddressProps[]>(
    [],
  );

  const handleBlurWithTrim = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      handleBlur(e);
      setFieldValue(name, value.trim());
    },
    [handleBlur, setFieldValue],
  );

  const handleInputMapChange = useCallback(
    debounce(async (value: string, inputField: 'pickup' | 'delivery') => {
      if (value) {
        const result = await getGooglePlacePredicitions(value, 'au');

        if (result) {
          const placeData = result.map(
            (res: { description: any; place_id: any }) => ({
              description: res.description,
              place_id: res.place_id,
            }),
          );

          if (inputField === 'pickup') {
            setPickupAddresses(placeData);
          } else {
            setDeliveryAddresses(placeData);
          }
        }
      }
    }, 300),
    [pickupAddresses, deliveryAddresses],
  );

  const handleAddressChange = useCallback(
    async (
      event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>,
      inputField: string,
    ) => {
      handleChange(event);
      const selectedItem = (
        inputField === 'pickupAddress' ? pickupAddresses : deliveryAddresses
      ).find((i) => i.description === event.target.value);

      if (selectedItem && selectedItem.place_id) {
        const result =
          selectedItem && (await getGooglePlaceDetails(selectedItem.place_id));
        result.address_components &&
          setFieldValue(
            `${inputField}.postcode`,
            result.address_components.find(
              (address: { types: string[] }) =>
                address.types && address.types[0] === 'postal_code',
            )?.long_name || '',
          );
        setFieldValue(
          `${inputField}.gps`,
          `${result.geometry.location.lat()},${result.geometry.location.lng()}`,
        );
        setFieldValue(`${inputField}.placeId`, selectedItem.place_id);
      }
    },
    [pickupAddresses, deliveryAddresses],
  );

  useEffect(() => {
    pickupAddress?.address &&
      setPickupAddresses([
        { description: pickupAddress?.address, place_id: '' },
      ]);
    deliveryAddress?.address &&
      setDeliveryAddresses([
        { description: deliveryAddress?.address, place_id: '' },
      ]);
  }, []);

  const generateAutocompleteItems = (
    addresses: AddressProps[],
  ): AutocompleteItem[] =>
    addresses.map(({ description }) => ({
      value: description,
      label: description,
    }));

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h4">Contact details</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item>
            <IconLock fontSize="small" className={classes.icon} />
          </Grid>
          <Grid item>
            <Typography variant="body1">
              We only require your details for communicating with you. They are
              secure and will not be shared outside of Dolittles.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {children}
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Input
              label="First name"
              name="contact.nameFirst"
              onChange={handleChange}
              onBlur={handleBlurWithTrim}
              value={contact?.nameFirst}
              touched={touched?.contact?.nameFirst}
              error={errors?.contact?.nameFirst}
              isErrorVisible={isErrorsVisible}
              innerRef={isFieldInFocus?.('contact.nameFirst')}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              label="Last name"
              name="contact.nameLast"
              onChange={handleChange}
              onBlur={handleBlurWithTrim}
              value={contact?.nameLast}
              error={errors?.contact?.nameLast}
              touched={touched?.contact?.nameLast}
              isErrorVisible={isErrorsVisible}
              innerRef={isFieldInFocus?.('contact.nameLast')}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              label="Email"
              name="contact.email"
              onChange={handleChange}
              onBlur={handleBlurWithTrim}
              value={contact?.email}
              error={errors?.contact?.email}
              touched={touched?.contact?.email}
              isErrorVisible={isErrorsVisible}
              innerRef={isFieldInFocus?.('contact.email')}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              label="Phone"
              name="contact.phone"
              onChange={handleChange}
              onBlur={handleBlurWithTrim}
              value={contact?.phone}
              error={errors?.contact?.phone}
              touched={touched?.contact?.phone}
              isErrorVisible={isErrorsVisible}
              innerRef={isFieldInFocus?.('contact.phone')}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Input
              label="Post code"
              name="contact.postcode"
              onChange={handleChange}
              onBlur={handleBlur}
              value={contact?.postcode}
              error={errors?.contact?.postcode}
              touched={touched?.contact?.postcode}
              required={false}
              isErrorVisible={isErrorsVisible}
              innerRef={isFieldInFocus?.('contact.postcode')}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <FormControl component="fieldset">
              <Radio
                aria-label="new customer"
                name="contact.isReturningCustomer"
                value={contact?.isReturningCustomer}
                onChange={handleChange}
                label={'Is new customer?'}
                menuItems={[
                  {
                    label: 'New Customer',
                    value: true,
                  },
                  {
                    label: 'Returning Customer',
                    value: false,
                  },
                ]}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl component="fieldset">
              <Radio
                aria-label="defence member"
                name="contact.isDefenceMember"
                value={contact?.isDefenceMember}
                onChange={handleChange}
                label={'Is defence member?'}
                menuItems={[
                  {
                    label: 'No',
                    value: false,
                  },
                  {
                    label: 'Yes',
                    value: true,
                  },
                ]}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl component="fieldset">
              <Radio
                aria-label="pensioner"
                name="contact.isPensioner"
                value={contact?.isPensioner}
                onChange={handleChange}
                label={'Is pensioner?'}
                menuItems={[
                  {
                    label: 'No',
                    value: false,
                  },
                  {
                    label: 'Yes',
                    value: true,
                  },
                ]}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Checkbox
              label="Special Arrangements?"
              menuItems={[
                {
                  name: 'isPickup',
                  label: 'Pickup',
                  value: !!isPickup,
                },
                {
                  name: 'isDelivery',
                  label: 'Delivery',
                  value: !!isDelivery,
                },
              ]}
              onChange={handleChange}
            />
          </Grid>
          {!!isPickup && (
            <Grid item xs={12} sm={6}>
              <Autocomplete
                menuItems={generateAutocompleteItems(pickupAddresses)}
                placeholder="Enter address"
                label="Pickup Address"
                name="pickupAddress.address"
                onInputChange={(value: string) => {
                  handleInputMapChange(value, 'pickup');
                }}
                onChange={(e) => {
                  handleAddressChange(e, 'pickupAddress');
                }}
                value={pickupAddress?.address || ''}
                error={errors?.pickupAddress?.address}
                controlledState
                bypassFilterOptions
                touched={touched.pickupAddress?.address}
                isErrorVisible={isErrorsVisible}
                onBlur={handleBlur}
                innerRef={isFieldInFocus?.('pickupAddress.address')}
              />
            </Grid>
          )}
          {!!isDelivery && (
            <Grid item xs={12} sm={6}>
              <Autocomplete
                menuItems={generateAutocompleteItems(deliveryAddresses)}
                placeholder="Enter address"
                label="Delivery Address"
                name="deliveryAddress.address"
                onInputChange={(value: string) => {
                  handleInputMapChange(value, 'delivery');
                }}
                onChange={(e) => handleAddressChange(e, 'deliveryAddress')}
                value={deliveryAddress?.address || ''}
                error={errors?.deliveryAddress?.address}
                controlledState
                bypassFilterOptions
                touched={touched.deliveryAddress?.address}
                isErrorVisible={isErrorsVisible}
                onBlur={handleBlur}
                innerRef={isFieldInFocus?.('deliveryAddress.address')}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
