import 'yup-phone';

import { useFormik } from 'formik';
import { navigate } from 'gatsby';
import React, { useCallback, useContext, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import * as yup from 'yup';

import { gql, useMutation } from '@apollo/client';
import { Input } from '@dogtainers/dgt-blocks/src/components/form';
import {
  Box,
  Button,
  Container,
  Grid,
  Snackbar,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { APP_ENV, RECAPTCHA_SITE_KEY } from '../../environment';
import { LoadingContext, ResponsiveContext } from '../../layouts/site.layout';
import { EMAIL, NAME, PHONE } from '../../utils';

export type ContactForm = {
  nameFirst: string;
  nameLast: string;
  email: string;
  phone: string;
  message: string;
};

const MUTATION_SUBMIT_CONTACT_FORM = gql`
  mutation SubmitContactForm($data: String!) {
    forms {
      createForm(environment: "${APP_ENV}", type: "contact", data: $data) {
        id
        type
        data
      }
    }
  }
`;

const validationSchema = yup.object().shape({
  nameFirst: yup
    .string()
    .matches(NAME, 'Please enter valid name')
    .min(2, 'Too Short!')
    .max(40)
    .required('First name is required'),
  nameLast: yup
    .string()
    .matches(NAME, 'Please enter valid name')
    .min(2, 'Too Short!')
    .max(40)
    .required('Last name is required'),
  email: yup
    .string()
    .matches(EMAIL, 'Invalid email')
    .required('Email is required'),
  phone: yup
    .string()
    .matches(PHONE, 'Invalid phone')
    .required('Phone is required'),
  message: yup
    .string()
    .required('Message is required')
    .test(
      'min-words',
      'Please provide more detail (min. 10 words)',
      (val) => String(val ?? '').split(' ').length >= 10,
    ),
});

const initialValues: ContactForm = {
  nameFirst: '',
  nameLast: '',
  email: '',
  phone: '',
  message: '',
};

const useStyles = makeStyles(() => {
  return {
    root: {
      paddingTop: 71,
    },
  };
});

const ContactPage = () => {
  const [isContactConfirmed, setContactConfirmed] = useState<boolean>();
  const [toastMsg, setToastMsg] = useState<string | null>(null);
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const classes = useStyles();
  const { setOverlay } = useContext(LoadingContext);
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values: ContactForm, { resetForm }) => {
      setOverlay('Submitting form..');
      try {
        await onSubmit(values);
        (window?.dataLayer as { push: (o: { event: string }) => void })?.push({
          event: 'contactFormSubmitted',
        });
        resetForm();
        setToastMsg('Form submitted!');
      } catch (e) {
        (window?.dataLayer as { push: (o: { event: string }) => void })?.push({
          event: 'contactFormSubmissionError',
        });
        if (e instanceof Error) {
          setToastMsg(e.message);
        }
      } finally {
        setOverlay(undefined);
      }
    },
  });

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    values,
    errors,
    touched,
    dirty,
    isValid,
    isSubmitting,
  } = formik;

  const [submitForm, { loading, error }] = useMutation(
    MUTATION_SUBMIT_CONTACT_FORM,
  );

  async function onSubmit(formData: ContactForm): Promise<void> {
    if (loading) {
      console.info('Submitting...');
    }
    if (error) {
      console.info(`Submission error! ${error.message}`);
      throw Error(
        'Unable to submit form - please try again, or give us a call',
      );
    }

    await submitForm({ variables: { data: JSON.stringify(formData) } });
  }

  function navigateToQuote() {
    const params = new URLSearchParams();
    for (const [key, value] of Object.entries(values)) {
      if (!value) {
        continue;
      }
      params.set(key, value);
    }
    const queryStr = params.toString();
    navigate(`/quote/${queryStr.length ? '?' + queryStr : ''}`);
  }

  const handleBlurWithTrim = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      handleBlur(e);
      setFieldValue(name, value.trim());
    },
    [handleBlur, setFieldValue],
  );

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!toastMsg}
        autoHideDuration={3000}
        onClose={() => setToastMsg(null)}
        message={toastMsg}
      />

      <Container maxWidth="md" className={classes.root}>
        <Box textAlign="center" mb="150px">
          <Typography variant="h2">Contact Us</Typography>
        </Box>

        <ResponsiveContext.Consumer>
          {({ breakpoint: _breakpoint }) => (
            <Box>
              <Grid container justifyContent="center" spacing={4}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h3">
                    We'd love to hear from you
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <form
                    noValidate
                    onSubmit={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    <Grid container direction="column" spacing={2}>
                      <Grid item>
                        <Input
                          label="Name"
                          name="nameFirst"
                          onChange={handleChange}
                          onBlur={handleBlurWithTrim}
                          value={values.nameFirst}
                          error={errors.nameFirst}
                          touched={touched.nameFirst}
                        />
                      </Grid>
                      <Grid item>
                        <Input
                          label="Last Name"
                          name="nameLast"
                          onChange={handleChange}
                          onBlur={handleBlurWithTrim}
                          value={values.nameLast}
                          error={errors.nameLast}
                          touched={touched.nameLast}
                        />
                      </Grid>
                      <Grid item>
                        <Input
                          label="Email Address"
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlurWithTrim}
                          value={values.email}
                          error={errors.email}
                          touched={touched.email}
                        />
                      </Grid>
                      <Grid item>
                        <Input
                          label="Phone"
                          name="phone"
                          onChange={handleChange}
                          onBlur={handleBlurWithTrim}
                          value={values.phone}
                          error={errors.phone}
                          touched={touched.phone}
                        />
                      </Grid>
                      {!isContactConfirmed && (
                        <Grid item xs={12}>
                          <Grid container justifyContent="space-around">
                            <Grid item>
                              <Button
                                variant="outlined"
                                onClick={() => navigateToQuote()}
                              >
                                New Quote Request
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button
                                variant="outlined"
                                onClick={() => setContactConfirmed(true)}
                              >
                                Something else
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      {isContactConfirmed && (
                        <>
                          <Grid item>
                            <Input
                              multiline
                              rows={5}
                              label="Message"
                              placeholder="Contents"
                              name="message"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.message}
                              error={errors.message}
                              touched={touched.message}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <ReCAPTCHA
                              sitekey={RECAPTCHA_SITE_KEY}
                              onChange={setRecaptchaToken}
                            />
                          </Grid>
                          <Grid item style={{ marginTop: '1rem' }}>
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              disabled={
                                !dirty ||
                                !isValid ||
                                !recaptchaToken ||
                                isSubmitting
                              }
                            >
                              Send Message
                            </Button>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </form>
                </Grid>
              </Grid>
              <Box py={35} textAlign="center">
                <Typography variant="h3" gutterBottom>
                  Your pet’s home away from home
                </Typography>
                <Button variant="outlined" size="large" href="/retreats">
                  Find a retreat
                </Button>
              </Box>
            </Box>
          )}
        </ResponsiveContext.Consumer>
      </Container>
    </>
  );
};

export default ContactPage;
