import { gql } from '@apollo/client';

export const GET_BOOKING = gql`
  query Bookings($getBookingId: ID!) {
    bookings {
      getBooking(id: $getBookingId) {
        id
        retreatId
        startDate
        endDate
        rooms {
          roomType
          petType
          quantity
        }
        totalCost {
          currency
          amount
        }
        status
        contact {
          countryId
          nameFirst
          nameLast
          email
          phone
          postcode
          isDefenceMember
          isPensioner
          isReturningCustomer
        }
        paymentIntentId
        isPaidOnline
        extras {
          type
          cost
        }
        extrasCost {
          amount
          currency
        }
        roomCost {
          amount
          currency
        }
        discount
        onlineBookingRestrictionReason
        deliveryAddres {
          placeId
          gps
          postcode
          address
        }
        pickupAddres {
          placeId
          gps
          postcode
          address
        }
      }
    }
  }
`;
