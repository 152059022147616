import { navigate } from 'gatsby';
import React, { FormEventHandler, useState } from 'react';

import { useMutation } from '@apollo/client';
import { Input } from '@dogtainers/dgt-blocks/src/components/form';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Snackbar,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

import { WhiteButton } from '../../components';
import { PAYMENT_BOOKING } from '../../graphql';
import { useEnsureQuoteForm, useStore } from '../../hooks';
import CardSVG from '../../images/payment/cardSVG';
import VisaSVG from '../../images/payment/visaSVG';
import { BookingStripe } from './types';

const useStyles = makeStyles(() => ({
  stripeForm: {
    '& .StripeElement': {
      border: '1px solid #999',
      padding: 10,
      background: 'white',
    },
  },
}));

const CardForm: React.FC = () => {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xs'));
  const [submitForm, { error }] = useMutation<BookingStripe>(PAYMENT_BOOKING);

  const [accept, setAccpet] = useState(false);
  const bookingQuote = useStore((state) => state.bookingQuote);

  const { nameFirst, nameLast, email, phone } = bookingQuote?.contact ?? {};
  const [billingDetails, setBillingDetails] = useState({
    firstName: nameFirst || '',
    lastName: nameLast || '',
    email: email || '',
    phone: phone || '',
  });

  const [toastMsg, setToastMsg] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);

  useEnsureQuoteForm();

  const handleSubmit: FormEventHandler = async (e) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    e.preventDefault();
    setIsProcessing(true);

    const card = elements?.getElement(CardElement);

    if (!stripe || !elements || !card || error || !bookingQuote) {
      setIsProcessing(false);
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { paymentMethod, error: paymentMethodError } =
      await stripe.createPaymentMethod({
        type: 'card',
        card,
        billing_details: {
          name: `${billingDetails.firstName} ${billingDetails.lastName}`,
          email: billingDetails.email,
          phone: billingDetails.phone,
        },
      });

    if (paymentMethodError) {
      setToastMsg(
        paymentMethodError.message ||
          'Failed creating payment method, please try again',
      );
      setIsProcessing(false);
      return;
    }

    if (paymentMethod) {
      try {
        // You can't call this route twice with same payment method, so you should recreate it every time
        // user creates some changes
        const result = await submitForm({
          variables: {
            bookingId: bookingQuote.id,
            paymentMethodId: paymentMethod.id,
          },
        });
        if (result.data) {
          const clientSecret =
            result.data?.bookings.initializeBookingPayment.clientSecret;

          if (clientSecret) {
            confirmCardPayment(clientSecret);
          } else {
            setToastMsg(
              'Try again or with different card. If you still have trouble contact us',
            );
            setIsProcessing(false);
          }
        }
      } catch (e) {
        console.log(e);
        setToastMsg('Payment failed');
        setIsProcessing(false);
      }
    }
  };

  const confirmCardPayment = async (clientSecret: string) => {
    if (!stripe) {
      setIsProcessing(false);
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error: stripeError } = await stripe.confirmCardPayment(
      clientSecret,
    );

    if (stripeError) {
      setIsProcessing(false);
      setToastMsg(stripeError.message || 'Payment failed');
      return;
    }

    setIsProcessing(false);
    navigate('/thank-you');
  };

  const handleChangeBillingDetails = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setBillingDetails({
      ...billingDetails,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!toastMsg}
        autoHideDuration={3000}
        onClose={() => setToastMsg(null)}
        message={toastMsg}
      />
      <Box py={5}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Input
              label="First name"
              name="firstName"
              onChange={handleChangeBillingDetails}
              value={billingDetails.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              label="Last name"
              name="lastName"
              onChange={handleChangeBillingDetails}
              value={billingDetails.lastName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              label="Email"
              name="email"
              onChange={handleChangeBillingDetails}
              value={billingDetails.email}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              label="Phone"
              name="phone"
              onChange={handleChangeBillingDetails}
              value={billingDetails.phone}
            />
          </Grid>
        </Grid>
      </Box>

      <Divider />
      <Box pt={5}>
        <Box
          mb={4}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h5">Payment by credit card</Typography>
          <Box display="flex" gridGap={10}>
            <CardSVG />

            <VisaSVG />
          </Box>
        </Box>
      </Box>

      <form id="payment-form" onSubmit={handleSubmit}>
        <Box mb={5} className={classes.stripeForm}>
          <CardElement
            id="card"
            options={{
              iconStyle: 'solid',
              style: {
                base: {
                  fontSize: '16px',
                },
              },
            }}
          />
        </Box>

        <Box mb={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={accept}
                onChange={(e) => setAccpet(e.target.checked)}
                name="readTerms"
              />
            }
            label="I have read and accepted the terms of use"
          />
        </Box>
        <Box
          display="flex"
          justifyContent="flex-end"
          flexDirection={!matches ? 'row' : 'column-reverse'}
          mb={2}
        >
          <Box mr={!matches ? 4 : 0} mt={matches ? 2 : 0}>
            <WhiteButton
              variant="contained"
              fullWidth
              onClick={() => navigate('/quote-confirmation')}
            >
              Back
            </WhiteButton>
          </Box>

          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!accept || isProcessing}
          >
            {isProcessing ? (
              <CircularProgress size={20} color="secondary" />
            ) : (
              'Confirm'
            )}
          </Button>
        </Box>
      </form>
    </>
  );
};

export default CardForm;
